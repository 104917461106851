.second-picker {
    background: none;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.second-languages {
    padding: 0;
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 10px;
    background: #FFFFFF;
}

.second-languages a {
    padding: 5px;
}