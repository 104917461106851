body {
    margin: 0;
    padding: 0;
    background: #F2F2F2;
    font-family: Montserrat;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1.2px;
    overflow-x: hidden;
}

h1 {
    margin: 0;
}

.app {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

header {
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    width: 100vw;
    padding: 21px 30px 21px 30px;
    justify-content: space-between;
    align-items: center;
}

header > div:first-child, nav {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    position: relative;
}

nav a {
    text-decoration: none;
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition: 0.5s all;
    letter-spacing: -0.6px;
}

nav a:hover {
    color: #FF784B;
}

.logo_a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    gap: 15px;
}

header > div:first-child > .logo_a > h1 {
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -1px;
    margin: 0;
}

main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.switch {
    display: flex;
    flex-direction: row;
    max-width: 90%;
}

.switch .url_active {
    border-radius: 0px 5px 5px 0px;
    background: #FF784B;
    display: inline-flex;
    padding: 8px 9px 9px 9px;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.6px;
    border: 0;
}

.switch .keyword_active {
    border-radius: 5px 0px 0px 5px;
    background: #FF784B;
    display: inline-flex;
    padding: 8px 9px 9px 9px;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.6px;
    border: 0;
}

.switch .url {
    border-radius: 0px 5px 5px 0px;
    background: #aeaeae;
    display: inline-flex;
    padding: 8px 9px 9px 9px;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.6px;
    border: 0;
}

.switch .keyword {
    border-radius: 5px 0px 0px 5px;
    background: #aeaeae;
    display: inline-flex;
    padding: 8px 9px 9px 9px;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.6px;
    border: 0;
}

.search_engine {
    width: 700px;
    border-radius: 25px;
    background: #FFF;
    box-shadow: 0px 0px 7.5px 0px rgba(0, 0, 0, 0.25);
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

orange {
    color: #FF784B;
}

.search_engine h1 {
    font-size: 24px;
    font-weight: 500
}

.search_form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.search_form input {
    width: 400px;
    font-family: Montserrat;
    border-radius: 25px;
    border: 1px solid rgba(175, 175, 175, 0.51);
    background: #FFF;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
}

.search-button {
    border: 0;
    width: 140px;
    transition: 0.5s all;
    padding: 9px 25px 8px 24px;
    justify-content: center;
    align-items: center;
}

.search-button.active {
    border-radius: 5px;
    background: #FF784B;
    color: white;
}

.search-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    border: 0;
    border-radius: 5px;
    width: 140px;
    transition: 0.5s all;
    padding: 9px 25px 8px 24px;
    justify-content: center;
    align-items: center;
}

.video-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 15px;
}

.video-card {
    width: 225px;
    max-height: 330px;
    padding: 10px 6px;
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 0px 7.5px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.video-card img {
    display: flex;
    width: 213px;
    height: 142px;
    flex-shrink: 0;
    border-radius: 5px;
    margin-bottom: 6px;
}

.duration_content h3 {
    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.7px;
}

.duration_content p {
    color: #000;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.55px;
}

.download_mp3 {
    border-radius: 40px;
    background: #FF784B;
    display: flex;
    justify-content: center;
    padding: 6px 0px;
    width: 213px;
    align-items: center;
    border: 0;
    color: #FFF;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.55px;
}

.watch_on_youtube {
    border-radius: 40px;
    background: #aeaeae;
    display: flex;
    justify-content: center;
    padding: 6px 0px;
    width: 213px;
    align-items: center;
    border: 0;
    color: #FFF;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.55px;
    text-decoration: none;
}

.card-buttons {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.load_more {
    border-radius: 5px;
    background: #FF784B;
    display: flex;
    justify-content: center;
    padding: 6px 0px;
    width: 163px;
    align-items: center;
    border: 0;
    color: #ffffff;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.55px;
    text-decoration: none;
}

.take_space {
    flex-grow: 1;
    width: 100%;
    height: 100%;
}

#about {
    width: 700px;
    border-radius: 25px;
    background: #FFF;
    box-shadow: 0px 0px 7.5px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#about h1 {
    font-size: 24px;
    font-weight: 500;
}

#about .content {
    max-width: 90%;
    margin: 0 auto;
}

.app-footer {
    background: #FFF;
    box-shadow: 0px 0px 8.3px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    width: 100vw;
    height: 60px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.app-footer p {
    margin: 0;
}

.sitemap div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.picker {
    border: 0;
    display: flex;
    background: none;
    justify-content: center;
    align-items: center;
    position: relative;
}

.languages {
    position: absolute;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    justify-content: center;
    align-items: center;
    right: 0;
    margin: 0;
    padding: 0;
    top: 30px;
    margin: 0 auto;
}

.languages a {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: 0;
    padding: 10px 5px;
    background: #FFFFFF;
    transition: 0.2s all;
}
.languages a:hover {
    background: rgba(255, 120, 75, 0.23);
}

@media screen and (max-width: 770px) {
    .search_engine, #about {
        max-width: 500px;
    }

    .video-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 15px;
        grid-row-gap: 15px;
    }
}

a {
    color: #FF784B;
    transition: 0.2s all;
}

@media screen and (max-width: 510px) {
    .search_engine, #about {
        max-width: 300px;
    }

    .search_engine h1, #about h1 {
        font-size: 16px
    }

    .search_form input {
        max-width: 270px;
        font-size: 12px;
        height: 30px;
    }

    .switch .keyword, .switch .url, .switch .keyword_active, .switch .url_active, .search-button {
        font-size: 12px;
    }

    #about .content p {
        font-size: 12px;
    }

    .video-list {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 15px;
        grid-row-gap: 15px;
    }

    #root > div > header > nav > a:nth-child(1),
    #root > div > header > nav > a:nth-child(2),
    #root > div > header > nav > a:nth-child(3) {
        display: none;
    }

    .app-footer p {
        font-size: 12px;
    }

    li {
        font-size: 12px;
    }

    h1 {

    }
}
